<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <pz-direction
        v-model="formulario2.direccion"
        viewline
      />
      <label>{{ $t('forms_flujo.form2_persona.BNF.datos_particulares') }}</label>
      <hr
        class="pz-dotted"
        style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="genero"
            :label="$t('forms_flujo.form1_persona.SP.genero')"
            :error="errors.get('genero')"
          >
            <el-select
              ref="genero"
              v-model="formulario2.genero"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
            >
              <el-option
                v-for="item in genero"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.UY.civil_state')"
            :error="errors.get('estado_civil_id')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario2.estado_civil_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.UY.civil_state')"
            >
              <el-option
                v-for="item in marital_status"
                :key="item.id"
                :label="item.estado"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.UY.civil_state')"
              />
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
                  prop="pais_nacimiento"
                  :label="$t('forms_flujo.form1_persona.SP.nacionalidad')"
          >
            <el-select
                ref="pais_nacimiento"
                v-model="formulario2.pais_nacimiento"
                clearable
                filterable
                :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
            >
              <el-option
                  v-for="item in paises"
                  :key="item.id"
                  :label="item.nacionalidad"
                  :value="item.id"
                  :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email"
            :label="$t('forms_flujo.form2_persona.UY.email')"
            :error="errors.get('email')"
          >
            <el-input
              v-model="formulario2.email"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.email')"
            />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
            prop="sucursal_id"
            :label="$t('forms_flujo.form1_persona.UY.sucursales')"
            :error="errors.get('sucursal_id')"
          >
            <el-select
              ref="sucursal_id"
              v-model="formulario2.sucursal_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.UY.sucursales')"
            >
              <el-option
                v-for="item in sucursales"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.UY.sucursales')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <label>{{ $t('forms_flujo.form2_persona.BNF.datos_laborales') }}</label>
      <hr
              class="pz-dotted"
              style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.empresa_trabajo"
                  :label="$t('forms_flujo.form2_persona.BNF.empresa_trabajo')"
                  :error="errors.get('info_extra.empresa_trabajo')"
          >
            <el-input
                    ref="info_extra.empresa_trabajo"
                    v-model="formulario2.info_extra.empresa_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.BNF.empresa_trabajo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.direccion_trabajo"
                  :label="$t('forms_flujo.form2_persona.BNF.direccion')"
                  :error="errors.get('info_extra.direccion_trabajo')"
          >
            <el-input
                    ref="info_extra.direccion_trabajo"
                    v-model="formulario2.info_extra.direccion_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.BNF.direccion')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {required, type} from "@/commons/utils/Rules";
    import {
        getDepartamentos,
        getEstadoCivil,
        getPaises,
        getSucursales
    } from "@/routes/api/resources";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";

    export default {
        name: 'PzPersonFormExtVehiculo',
        components: {PzFormFlujo, PzDirection, PzIdentityDocument},
        mixins: [baseform2],
        data() {
            var check_Jubilado = (rule, value, callback) => {
                if (!this.isjubilado && !value) {
                    return callback(new Error(this.$t('rules.incomplete')));
                }
                return callback();
            };
            return {
                marital_status: [],
                sucursales: [],
                paises: [],
                provincias: [],
                genero: [
                    {id: 'f', name: this.$t('forms_flujo.form1_persona.SP.femenino')},
                    {id: 'm', name: this.$t('forms_flujo.form1_persona.SP.masculino')}
                ],
                formulario2: {
                    direccion: {},
                    provincia: '',
                    email: '',
                    estado_civil_id:'',
                    sucursal_id:'',
                    info_extra: {
                        empresa_trabajo: '',
                        direccion_trabajo: '',

                    },
                    genero:'',

                }
                ,
                disable: true,
                rules_formulario2:
                    {
                        tipo_vivienda: [
                            required('')
                        ],
                        direccion: [
                            required('')
                        ],
                        provincia: [
                            required('')
                        ],
                        email: [
                            required(''),
                            type({field: '', type: 'email'})
                        ],
                        estado_civil_id: [
                            required('')
                        ],
                        sucursal_id: [
                            required('')
                        ],
                        info_extra: {
                            direccion: [required('')],
                            empresa_trabajo: [{validator: check_Jubilado, trigger: 'blur'}],
                        },
                        genero: [
                            required('')
                        ],
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            };
        },
        created: function () {
            let data = localStorage.getItem('isjubilado')
            if(data && data=='true')
                this.isjubilado =  true;
            else
                this.isjubilado = false;
        },
        beforeMount() {
            let estado = getEstadoCivil().then((res) => {
                this.marital_status = res.data;
            }).catch(() => {
                this.marital_status = [];
            });
            let paises = getPaises().then((res) => {
                this.paises = res.data;
            }).catch(() => {
                this.paises = [];
            });
            var dep = getDepartamentos().then((res) => {
                this.provincias = res.data;
            }).catch(() => {
                this.provincias = [];
            });

            let getsucursales = getSucursales( this.$route.params.id_custom).then((res) => {
                this.sucursales = res.data;
            }).catch(() => {
                this.sucursales = [];
            });

            Promise.all([estado, dep, paises, getsucursales]).then(() => {

            }).finally(() => {this.$store.dispatch('loader/down',{trigger:this.$options.name})});
        },
        methods: {},
    }
</script>

